import React from "react";
import Layout from "../components/Layout";
import Team from "../components/Team";
import Seo from "../components/Seo";

// markup
const TeamPage = () => {
  return (
    <>
      <Seo pathname="/team" />
      <Layout title="Team">
        <Team />
      </Layout>
    </>
  );
};

export default TeamPage;
